/* Tailwind CSS */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ui package */
@import "../../../packages/ui/src/styles/export.src.css";

/* base */
@import "./_fonts.css";
@import "./_layout.css";

/* pages */

/* components */
@import "../components/CompanyLogosMarquee/CompanyLogosMarquee.css";
@import "../components/LandingHeader/LandingHeader.css";
