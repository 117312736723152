@layer utilities {
  .outline-brand {
    @apply outline-none ring ring-brand ring-opacity-50;
  }

  .outline-white {
    @apply outline-none ring ring-white ring-opacity-50;
  }

  .outline-accent {
    @apply outline-none ring ring-accent ring-opacity-50;
  }

  .outline-green {
    @apply outline-none ring ring-green-500 ring-opacity-50;
  }

  .outline-red {
    @apply outline-none ring ring-red-500 ring-opacity-50;
  }

  .outline-blue {
    @apply outline-none ring ring-blue-500 ring-opacity-50;
  }
}
