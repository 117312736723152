@layer components {
  .marquee {
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marquee 42s linear infinite;
  }

  .marquee--offset-1 {
    --offset: 100%;
    --move-initial: calc(-100% + var(--offset));
    --move-final: calc(-200% + var(--offset));
  }
  .marquee--offset-2 {
    --offset: 200%;
    --move-initial: calc(-100% + var(--offset));
    --move-final: calc(-200% + var(--offset));
  }

  @keyframes marquee {
    0% {
      transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
      transform: translate3d(var(--move-final), 0, 0);
    }
  }
}
