@layer components {
  .divider {
    @apply text-gray-500;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .divider::before,
  .divider::after {
    @apply border-b border-solid border-gray-400;
    content: "";
    flex: 1;
  }

  .divider::before {
    margin-right: 0.25em;
  }

  .divider::after {
    margin-left: 0.25em;
  }
}
