@layer components {
  .footer-bg-layer-0 {
    width: 100%;
    position: absolute;
    background: linear-gradient(10deg, #181b20 10%, #000);
    transform: skewY(-4deg);
    transform-origin: 0;
    height: 500px;
    z-index: 3;
    bottom: -300px;
    left: 0;
  }

  .footer-bg-layer-1 {
    width: 100%;
    position: absolute;
    background: linear-gradient(10deg, #fff 10%, #f2f2f2);
    transform: skewY(-5.5deg);
    transform-origin: 0;
    height: 500px;
    z-index: 2;
    bottom: calc(-300px + 16px);
    left: 0;
  }
}
