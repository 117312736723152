@layer components {
  @media (max-width: 640px) {
    .container {
      max-width: 360px;
    }
  }

  .search-job-posting__markdown {
    h2 {
      @apply text-xl mt-2 font-medium;
    }

    h2:first {
      @apply mt-0;
    }

    ul {
      @apply list-disc list-inside ml-4 mb-2;
    }
  }

  .input__range {
    @apply appearance-none bg-transparent cursor-pointer;
  }

  .input__range::-webkit-slider-runnable-track {
    @apply appearance-none bg-gray-500/25 rounded-full h-1.5;
  }

  .input__range::-webkit-slider-thumb {
    @apply appearance-none bg-brand h-4 w-4 rounded-full relative -mt-1;
  }

  .input__range::-moz-range-track {
    @apply appearance-none bg-gray-500/25 rounded-full;
  }

  .input__range::-ms-track {
    @apply appearance-none bg-gray-500/25 rounded-full;
  }
}

@layer base {
  body {
    font-family: "Roboto", sans-serif;
    font-display: swap;
  }

  b,
  strong {
    font-weight: 500;
  }

  .aspect-ratio-16\/9 {
    padding-bottom: 56.25%;
  }
}

@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
