@layer components {
  .checkbox > input[type="checkbox"] {
    @apply absolute outline-none;
    z-index: -9999;
    opacity: 0;
  }

  .checkbox > input[type="checkbox"] + div > span {
    @apply relative;
    padding-left: 1.75rem;
    line-height: 1.7;
  }

  .checkbox > input[type="checkbox"]:focus + div > span {
    @apply outline-brand rounded;
  }

  .checkbox > input[type="checkbox"] + div > span::before {
    @apply absolute rounded-sm border-brand border-2 bg-white block;
    content: "";
    top: 4px;
    left: 4px;
    width: 15px;
    height: 15px;
  }

  .checkbox > input[type="checkbox"]:checked + div > span::after {
    @apply absolute bg-black;
    content: "";
    top: 7px;
    left: 7px;
    width: 9px;
    height: 9px;
  }
}
