@layer components {
  .radio > input[type="radio"] {
    @apply absolute outline-none;
    z-index: -9999;
    opacity: 0;
  }

  .radio > input[type="radio"] + span {
    @apply btn relative;
    padding-left: 1.75rem;
    line-height: 1.7;
  }

  .radio > input[type="radio"]:focus + span {
    @apply outline-brand;
  }

  .radio > input[type="radio"] + span::before {
    @apply absolute rounded-full border-brand border-2;
    content: "";
    top: 4px;
    left: 4px;
    width: 15px;
    height: 15px;
  }

  .radio > input[type="radio"]:checked + span::after {
    @apply absolute bg-black rounded-full;
    content: "";
    top: 7px;
    left: 7px;
    width: 9px;
    height: 9px;
  }
}
