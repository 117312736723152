@layer components {
  .landing-header__box {
    position: relative;
  }

  .landing-header__box::before {
    position: absolute;
    /* We need to consider the skewing of the background */
    bottom: 5%;
    /* Must be half of the percentage added to the width/height */
    left: -15%;
    z-index: -1;
    content: "";
    /* We need to consider the skewing of the background */
    width: calc(100% * 1.3);
    height: calc(100% * 1.3);
    transform: skewY(-4deg);
    transform-origin: 50% bottom;
    background: linear-gradient(18deg, #f2f2f2 00%, #fff 45%);
  }
}
