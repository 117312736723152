/**
* Transitions
*/
@layer utilities {
  .transition-width {
    transition-property: width;
  }

  .transition-opacity {
    transition-property: opacity;
  }

  .transition-bg {
    transition-property: background-color;
  }

  /**
  * Transition durations
  */
  .duration-15 {
    transition-duration: 0.15s;
  }

  .duration-35 {
    transition-duration: 0.35s;
  }

  .duration-100 {
    transition-duration: 1s;
  }

  /**
  * Transition timing functions
  */
  .ease-in-out {
    transition-timing-function: ease-in-out;
  }
}
