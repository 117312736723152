@layer components {
  .btn--gradient {
    /**
    * Using linear-gradient as a background 
    * will create a strange effect on transparent borders.
    * We remove the border for that reason, but need to add
    * the border width to the padding so that each button
    * appears to be the same size.
    */
    border: none;
    padding: calc(0.25rem + 2px) calc(1rem + 2px);
  }

  .btn--gradient.btn--gradient-brand {
    background: #181b20;
    background: linear-gradient(165deg, black 0%, #181b20 100%);
  }

  .btn--gradient.btn--gradient-accent {
    background: #ff4340;
    background: linear-gradient(165deg, #e42421 10%, #ff9253 100%);
  }

  .btn {
    @apply relative rounded border-2 border-solid;
    min-width: 60px;
    min-height: 28px;
  }

  .btn:focus {
    @apply outline-none;
  }

  .btn-overlay-white:hover:before,
  .btn-overlay-white:focus:before {
    @apply absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    content: "";
    z-index: 1;
    background: rgba(255, 255, 255, 0.12);
  }
}
